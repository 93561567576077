import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Quicklinks from '../../components/Quicklinks';
import Image from '../../components/Image';
import OutboundLink from '../../components/OutboundLink';
import Hero from '../../components/Hero';
import Action from '../../components/Resource/Action';
import '../../styles/pages/_classroom-instruction.scss';

const InstructionalSupportPage = () => {
  return (
    <Layout title="Instructional Support">
      <Hero
        mediaSource={'hero-is@2x.jpg'}
        className={'hero--large mb-0 pb-0'}
        color={'blue'}
      >
        <h1>Instructional Support</h1>
        <p>
          Through this collection, educators are equipped with strategies to
          engage students from diverse racial, ethnic, and cultural groups
          providing them with quality, equitable, and liberating educational
          experiences that validates and affirms student identity. Units are
          designed to be problem-based and focus on workforce skill development
          to empower students with the knowledge and tools to be change agents
          in reducing health disparities in communities. The instructional
          supports below allow educators to take a deep dive into the diverse
          strategies and practices employed within this collection.
        </p>
      </Hero>
      <Section className="mt-0">
        <Container fullWidth className="container">
          <Quicklinks
            className="pl-0"
            title=""
            smoothScroll
            links={[
              {
                target: '#responsive-instruction',
                label: 'CULTURALLY AND LINGUISTICALLY RESPONSIVE INSTRUCTION',
              },
              {
                target: '#product-lifecycle',
                label: 'CONNECTION TO THE PRODUCT LIFE CYCLE',
              },
              {
                target: '#sel',
                label: 'SOCIAL AND EMOTIONAL LEARNING',
              },
              {
                target: '#computational',
                label: 'COMPUTATIONAL THINKING PRACTICES',
              },
              {
                target: '#advancing-inclusive-research',
                label: 'ADVANCING INCLUSIVE RESEARCH',
              },
              {
                target: '#standards-alignment',
                label: 'STANDARDS ALIGNMENT',
              },
            ]}
          />
        </Container>
      </Section>
      {/* CULTURALLY AND LINGUISTICALLY RESPONSIVE INSTRUCTION */}
      <Section className="instructional__responsive-instruction-section pt-0 pb-0 pb-0">
        <Container
          fullWidth
          className="pr-0 pb-0 pl-0 container--complete-width"
        >
          <Row id="responsive-instruction" className="mr-0 pb-0 mb-0">
            <Column size={6} className="mb-0 mr-0 pr-0">
              <Image filename="img-is-cultural.jpg" className="ml-0 mr-0" />
            </Column>
            <Column
              xlargeOffset={1}
              xlarge={5}
              size={6}
              className="mt-0 ml-0 mr-0 pt-0 pl-0 pr-0"
            >
              <div className="instructional__responsive-instruction pt-3 pb-1.5 pl-1.5">
                <h2 className="text-bold">
                  Culturally and Linguistically Responsive Instruction
                </h2>
                <p>
                  Culturally and Linguistically Responsive Instruction is an
                  approach that leverages and utilizes the culturally diverse
                  instructional strategies and practices. These practices allow
                  students to leverage their strengths and culture in the
                  classroom in order to make instruction more effective and
                  relevant.
                </p>
                <Action
                  type="download"
                  label="Learn More"
                  path="/pdfs/instructional-support/Gen-IS-InstructionalResources-Strategies.pdf"
                  format=".PDF,"
                  size="48.3 KB"
                />
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* CONNECTION TO THE PRODUCT LIFE CYCLE SECTION */}
      <Section className="instructional__lifecycle-section pt-0 pb-0 pr-0 pl-0">
        <Container fullWidth className="pr-0 pl-0 container--complete-width">
          <Row id="product-lifecycle" className="mr-0">
            <Column
              xlargeOffset={1}
              xlarge={5}
              size={6}
              className="mb-0 pr-0 mr-0"
            >
              <div className="instructional__lifecycle pb-1.5 pt-3">
                <h2 className="text-bold">
                  Connection to the Product Life Cycle
                </h2>
                <p>
                  Aligned to industry standards, this collection of lessons
                  embeds the phases of the Product Life Cycle throughout the
                  units. There are four phases involved in the Product Life
                  Cycle of Medicine: discover, develop, manufacture and
                  commercialize. As students discover more about the Product
                  Life Cycle, they will also learn about the careers associated
                  with each phase of development.
                </p>
                <Action
                  type="download"
                  label="Learn More"
                  path="/pdfs/instructional-support/Gen-IS-InstructionalResources-Product.pdf"
                  format=".PDF,"
                  size="56.3 KB"
                  lightMetadata
                />
              </div>
            </Column>
            <Column size={6} className="ml-0 pl-0 pr-0">
              <Image
                className="instructional__section-image"
                filename="img-is-product.jpg"
              />
            </Column>
          </Row>
        </Container>
      </Section>
      {/* COMPUTATIONAL THINKING SECTION */}
      <Section className="instructional__computational-section pt-0 pb-0">
        <Container fullWidth className="pr-0 pl-0 container--complete-width">
          <Row id="computational" className="mr-0 mb-0">
            <Column
              xlargeOffset={1}
              xlarge={5}
              size={6}
              className="mb-0 pr-0 pl-0"
            >
              <div className="instructional__computational pr-2 pt-3 pb-1.5">
                <h2 className="text-bold">Computational Thinking Practices</h2>
                <p>
                  Computational Thinking is formalized problem solving using a
                  set of strategies to analyze a problem and develop innovative
                  solutions. It takes into account 21st century technology and
                  overlaps it with key strategies to look at a problem
                  systematically, develop a way to tackle it, and work toward a
                  solution. By teaching students to solve problems in a way that
                  a computer or human could understand, we equip them with the
                  ability to think critically and translate their understanding
                  to multiple scenarios and situations.
                </p>
                <Action
                  type="download"
                  label="Learn More"
                  path="/pdfs/instructional-support/Gen-IS-InstructionalResources-CT.pdf"
                  format=".PDF,"
                  size="116 KB"
                  className="color-white"
                />
              </div>
            </Column>
            <Column size={6} className="ml-0 pl-0 pr-0">
              <Image
                className="instructional__section-image"
                filename="img-is-computational.jpg"
              />
            </Column>
          </Row>
        </Container>
      </Section>
      {/* SEL SECTION */}
      <Section className="instructional__sel-section pt-0 pb-0">
        <Container fullWidth className="pr-0 pl-0 container--complete-width">
          <Row id="sel" className="mr-0 mb-0">
            <Column
              xlargeOffset={1}
              xlarge={5}
              size={6}
              className="mb-0 pr-0 pl-0"
            >
              <div className="instructional__sel pr-2 pt-3 pb-1.5">
                <h2 className="text-bold">Social-Emotional Learning</h2>
                <p>
                  Social-Emotional Learning is an approach to education that
                  factors students’ emotional wellbeing into the process of
                  academic and personal growth. Futurelab+ curriculum resources
                  are aligned to and embedded with the core SEL competencies
                  created by The Collaborative for Academic, Social, and
                  Emotional Learning (CASEL), in order to facilitate the most
                  engaging and equitable learning experiences possible for all
                  students.{' '}
                </p>

                <Action
                  type="download"
                  label="Learn More"
                  path="/pdfs/instructional-support/Gen-IS-InstructionalResources-SEL.pdf"
                  format=".PDF,"
                  size="42 KB"
                  lightMetadata
                />
              </div>
            </Column>
            <Column size={6} className="ml-0 pl-0 pr-0">
              <Image
                className="instructional__section-image"
                filename="img-instructional-SEL-bg.jpg"
              />
            </Column>
          </Row>
        </Container>
      </Section>
      {/* ADVANCING INCLUSIVE RESEARCH SECTION */}
      <Section className="instructional__research-section pt-0 pb-0">
        <Container fullWidth className="pr-0 pl-0 container--complete-width">
          <Row id="advancing-inclusive-research" className="mr-0 pr-0 pl-0">
            <Column size={6} className="mb-0 ml-0 mr-0 pr-0 pl-0">
              <Image
                className="instructional__section-image"
                filename="img-is-standards.jpg"
              />
            </Column>
            <Column
              xlargeOffset={1}
              xlarge={5}
              size={6}
              className="mt-0 ml-0 mr-0 pt-0 pl-0 pr-0"
            >
              <div className="instructional__research pt-3 pb-1.5 pl-1.5">
                <h2 classame="text-bold">Advancing Inclusive Research</h2>
                <p>
                  Genentech is working to alleviate health disparities and
                  access to clinical trials. Their{' '}
                  <OutboundLink to="https://www.gene.com/patients/advancing-inclusive-research">
                    Advancing Inclusive Research
                  </OutboundLink>{' '}
                  initiative helps more patients of color access potentially
                  life-saving clinical trials and providing the scientific
                  community with a greater diversity of data. By collecting as
                  much data from diverse populations, drug manufacturers can
                  better develop drugs that work for everyone.
                </p>
                <Action
                  type="download"
                  label="Learn More"
                  path="/pdfs/instructional-support/Gen-IS-InstructionalResources-Inclusive.pdf"
                  format=".PDF,"
                  size="45.2 KB"
                  lightMetadata
                />
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* STANDARDS ALIGNMENT SECTION */}
      <Section className="instructional__standards-section pt-0 pb-0">
        <Container fullWidth className="pr-0 pl-0 container--complete-width">
          <Row id="standards-alignment" className="mr-0">
            <Column
              xlargeOffset={1}
              xlarge={5}
              size={6}
              className="mt-0 mb-0 mr-0 pb-0 pr-0 "
            >
              <div className="instructional__standards pt-5 pb-1.5 pr-2">
                <h2 className="text-bold">Standards Alignment</h2>
                <p className="pb-2">
                  Instructional resources are aligned to national standards to
                  modernize and authentically engage students in everything from
                  patent research and drug discovery to highlighting relevant
                  skills and careers.
                </p>
                <OutboundLink to="https://www.nextgenscience.org/">
                  <p className="instructional__standards-subheading text-bold pb-0.5">
                    NGSS
                  </p>
                </OutboundLink>
                <p className="pb-4">
                  The Next Generation Science Standards (NGSS) are K–12 science
                  content standards. Standards set the expectations for what
                  students should know and be able to do. The NGSS were
                  developed by states to improve science education for all
                  students.
                </p>
                <OutboundLink to="https://www.cteonline.org/standards/CTE.HSMT/5245">
                  <p className="instructional__standards-subheading text-bold pb-0.5">
                    CTE Biotechnology Pathway
                  </p>
                </OutboundLink>
                <p>
                  The Career Technical Education (CTE) Model Curriculum
                  Standards publication is organized for use as a complete
                  document or for access to individual industry sectors and
                  pathways. The document includes Standards for Career Ready
                  Practice—which describe the knowledge and skills that students
                  need prior to entering a career technical education program—as
                  part of the career technical education sequence or as
                  integrated elements of other course work in preparation for
                  careers and college.
                </p>
                <Action
                  type="download"
                  label="Learn More"
                  path="https://www.cde.ca.gov/ci/ct/sf/documents/healthmedical.pdf"
                  format=".PDF,"
                  size="1.5 MB"
                />
              </div>
            </Column>
            <Column size={6} className="mb-0 mr-0 pl-0 pr-0">
              <Image
                className="instructional__section-image"
                filename="img-is-standards-alignment.png"
              />
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default InstructionalSupportPage;
